import RequestSideMenu from "../Component/RequestComponent/RequestSideMenu";
import MainHeader from "../Component/Header";
import {
  Layout,
  theme,
  Upload,
  Button,
  Image,
  List,
  Space,
  Radio,
  Flex,
  Input,
  Spin,
  Tooltip,
  message,
} from "antd";
import {
  ArrowLeftOutlined,
  EditOutlined,
  DownloadOutlined,
  SendOutlined,
  LinkOutlined,
} from "@ant-design/icons";
import styled from "styled-components";
import dayjs from "dayjs";
import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import VirtualList from "rc-virtual-list";
import { useGlobalContext } from "../Component/GlobalContext";
import { gql, useMutation } from "@apollo/client";
import { uploadData, getUrl, remove } from "aws-amplify/storage";
import withThemeSwitch from "../Component/withThemeSwitch";

function getStatusColor(status) {
  switch (status) {
    case "New Updated":
      return "green";
    case "On Progress":
      return "cyan";
    case "Finished":
      return "red";
    case "Pending":
      return "orange";
    case "Paid":
      return "blue";
    default:
      return "grey";
  }
}

const ADD_COMMENT = gql`
  mutation AddComment($requestUid: uuid!, $content: String!, $image: String) {
    insert_request_comment_one(
      object: { request_id: $requestUid, content: $content, image: $image }
    ) {
      id
    }
  }
`;

function RequestDetails() {
  const [height, setHeight] = useState(window.innerHeight);
  const [width, setWidth] = useState(window.innerWidth);
  const [inputMessage, setInputMessage] = useState("");
  const location = useLocation();
  const requestUid = location.state?.requestUid;
  const navigate = useNavigate();
  const [isMounted, setIsMounted] = useState(false);
  const [requestDetails, setRequestDetails] = useState({});
  const [commentList, setCommentList] = useState([]);
  const [resolvedCommentList, setResolvedCommentList] = useState([]);
  const [uploadFile, setUploadFile] = useState([]);

  const {
    RequestDetailData: data,
    fetchRequestDetail: fetchData,
    RequestDetailLoading: loading,
    fetchComments,
    CommentsData,
    CommentsLoading,
    handleRefetchComments,
    setIsCommentsRefetching,
  } = useContext(useGlobalContext);
  const [commentSelection, setCommentSelection] = useState("active");
  const [addComment] = useMutation(ADD_COMMENT);

  const onCommentMenuClick = (e) => {
    console.log(e.target.value);
    setCommentSelection(e.target.value);
  };
  const formatFileName = (file) => {
    const fileNameParts = file.name.split(".");
    const extension = fileNameParts.pop();
    const baseName = fileNameParts.join(".");
    const timestamp = Date.now();
    const newFileName = `${baseName}-${timestamp}.${extension}`;
    return newFileName;
  };
  function deleteTempRequest() {
    if (uploadFile.length > 0) {
      try {
        console.log("Remove ", uploadFile);
        remove({
          path: uploadFile[0].path,
        });
      } catch (error) {
        console.log("Error ", error);
      }
      setUploadFile([]);
    }
  }

  function handleAddComment() {
    setIsCommentsRefetching(true);
    if (inputMessage !== "") {
      addComment({
        variables: {
          requestUid: requestUid,
          content: inputMessage,
          image: uploadFile.length > 0 ? uploadFile[0].path : "",
        },
      })
        .then(() => {
          setInputMessage("");
          setUploadFile([]);
          handleRefetchComments({ id: requestUid });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }
  const attachmentProps = {
    customRequest: async ({
      file,
      onSuccess,
      onError,
      onProgress: onProgressInternal,
    }) => {
      try {
        const formatedFileName = formatFileName(file);
        await uploadData({
          path: `public/${requestUid}/commentFile/${formatedFileName}`,
          data: file,
          options: {
            onProgress: async (progressEvent) => {
              const percent = Math.round(
                (progressEvent.transferredBytes / progressEvent.totalBytes) *
                  100
              );
              onProgressInternal({ percent }, file);
              if (percent === 100) {
                file.path = `public/${requestUid}/commentFile/${formatedFileName}`;
              }
            },
          },
        }).result;

        onSuccess(file);
      } catch (error) {
        file.status = "error";
        onError(error, file);
      }
    },

    accept: ".jpg,.jpeg,.png",
    maxCount: 1,
    onChange(info) {
      const { status, name } = info.file;
      if (status === "uploading") {
        info.file.name = formatFileName(info.file);
        setUploadFile([info.file]);

        if (status === "error") {
          message.error(`${name} file upload failed.`);
        }
      }
    },
    progress: {
      strokeColor: {
        "0%": "#108ee9",
        "100%": "#87d068",
      },
      style: {
        maxWidth: "20vw",
        fontSize: 12,
      },
      strokeWidth: 3,
      format: (percent) => percent && `${parseFloat(percent.toFixed(2))}%`,
    },
    onSuccess: (file) => {
      message.success(`${file.name} file uploaded successfully.`);
      setUploadFile([file]);
    },
    onError: (error, file) => {
      message.error(`${file.name} upload failed. Because ${error}`);
      file.status = "error";
      setUploadFile([file]);
    },
    onRemove: (info) => {
      try {
        console.log("Remove ", uploadFile);
        remove({
          path: uploadFile[0].path,
        });
      } catch (error) {
        console.log("Error ", error);
      }
      setUploadFile([]);
    },
  };

  //Convert the fetching data to the displayed table data
  useEffect(() => {
    if (CommentsData) {
      const activeComments = [];
      const resolvedComments = [];

      const promises = CommentsData.request_comment.map(async (element) => {
        let pictureUrl = null;
        if (element.image) {
          pictureUrl = await getUrl({
            path: element.image,
            options: {
              validateObjectExistence: true,
            },
          });
        }
        const comment = {
          uid: element.id,
          content: element.content,
          picture: pictureUrl ? pictureUrl.url.href : "",
          publishDate: dayjs(element.published_date).format("DD/MM/YYYY"),
          orderDate: element.published_date,
          state: element.status,
          resolvedDate: element.updated_date
            ? dayjs(element.updated_date).format("DD/MM/YYYY")
            : "-",
        };

        if (element.status === "active") {
          activeComments.push(comment);
        } else if (element.status === "resolved") {
          resolvedComments.push(comment);
        }
      });

      Promise.all(promises).then(() => {
        activeComments.sort(
          (a, b) => new Date(b.orderDate) - new Date(a.orderDate)
        );
        resolvedComments.sort(
          (a, b) => new Date(b.orderDate) - new Date(a.orderDate)
        );

        setCommentList(activeComments);
        setResolvedCommentList(resolvedComments);
      });
    } else {
      setCommentList([]);
      setResolvedCommentList([]);
    }
  }, [CommentsData]);

  useEffect(() => {
    if (data) {
      var detail = {};
      if (data.request_by_pk) {
        detail = {
          id: data.request_by_pk.id,
          actualPrice: data.request_by_pk.actual_price,
          dueDate: data.request_by_pk.due_date
            ? data.request_by_pk.due_date
            : "-",
          estimatePrice: data.request_by_pk.estimate_price
            ? data.request_by_pk.estimate_price
            : "TBD",
          status: data.request_by_pk.status,
          updateDate: data.request_by_pk.update_date,
          requestName: data.request_by_pk.title,
          requestDescription: data.request_by_pk.description,
          workStreams: data.request_by_pk.work_streams,
          constructionMethodology: data.request_by_pk.construction_methodology,
          methodologyBuilding: data.request_by_pk.methodology_building,
          methodologyPhase: data.request_by_pk.methodology_phase,
          methodologyOutputs: data.request_by_pk.methodology_outputs,
          modelDevelopment: data.request_by_pk.model_development,
          modelPhase: data.request_by_pk.model_phase,
          modelEquipment: data.request_by_pk.model_equipment,
          modelType: data.request_by_pk.model_type,
          modelUsage: data.request_by_pk.model_usage,
          fileDescription: [],
        };
      } else {
        detail = {
          id: data.id,
          actualPrice: data.actual_price,
          dueDate: data.due_date ? data.due_date : "-",
          estimatePrice: data.estimate_price ? data.estimate_price : "TBD",
          status: data.status,
          updateDate: data.update_date,
          requestName: data.title,
          requestDescription: data.description,
          workStreams: data.work_streams,
          constructionMethodology: data.construction_methodology,
          methodologyBuilding: data.methodology_building,
          methodologyPhase: data.methodology_phase,
          methodologyOutputs: data.methodology_outputs,
          modelDevelopment: data.model_development,
          modelPhase: data.model_phase,
          modelEquipment: data.model_equipment,
          modelType: data.model_type,
          modelUsage: data.model_usage,
          fileDescription: [],
        };
      }
      setRequestDetails(detail);
    }
  }, [data]);

  useEffect(() => {
    if (isMounted) {
      if (requestUid === undefined) {
        navigate("/request_list");
      } else {
        fetchData({
          variables: { requestUid: requestUid },
        });
        fetchComments({
          variables: { requestUid: requestUid },
        });
      }
    }
  }, [isMounted, navigate, requestUid, fetchData, fetchComments]);

  useEffect(() => {
    setIsMounted(true);
    const handleResize = () => {
      setHeight(window.innerHeight);
      setWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const {
    token: { colorBgContainer, colorBorder, colorBgLayout },
  } = theme.useToken();
  // Used to delete uploaded images when close the page
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      deleteTempRequest();
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  });
  // Used to delete uploaded images when leaving the page

  return (
    <MainHeader select="Request">
      <RequestSideMenu select="1" deleteCommentFile={deleteTempRequest}>
        {loading && (
          <div
            style={{
              width: "100%",
              height: height - 66,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              background: colorBgContainer,
            }}
          >
            <Spin tip="Loading..." size="large" />
          </div>
        )}
        {!loading && (
          <Layout.Content
            style={{
              marginLeft: 2,
              minHeight: 280,
              background: colorBgContainer,
              height: height - 66,
              overflowY: "hidden",
            }}
          >
            <div
              style={{ width: "100%", height: 150, padding: 24, paddingTop: 0 }}
            >
              <Button
                type="text"
                icon={<ArrowLeftOutlined style={{ fontSize: 30 }} />}
                onClick={() => {
                  deleteTempRequest();
                  navigate(-1);
                }}
              />
              <Space
                direction="vertical"
                style={{
                  paddingLeft: 0.05 * (width - 220),
                }}
              >
                <div>
                  <h1 style={{ marginBottom: 12 }}>
                    {requestDetails.requestName}
                    <Button
                      type="text"
                      style={{ marginLeft: 48 }}
                      icon={<EditOutlined style={{ fontSize: 24 }} />}
                      onClick={() => {
                        deleteTempRequest();
                        navigate("/request_creation", {
                          state: {
                            requestDetails: requestDetails,
                          },
                        });
                      }}
                    />
                  </h1>
                </div>
                <Tooltip
                  title={requestDetails.requestDescription}
                  overlayStyle={{ maxWidth: "50vw" }}
                  placement="bottomLeft"
                  trigger="hover"
                >
                  <div style={{ fontSize: 16 }}>
                    <span style={{ paddingRight: 12, verticalAlign: "middle" }}>
                      Descirption:
                    </span>
                    <span
                      style={{
                        maxWidth: width / 3,
                        display: "inline-block",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        verticalAlign: "middle",
                      }}
                    >
                      {requestDetails.requestDescription}
                    </span>
                  </div>
                </Tooltip>
                <div style={{ fontSize: 16 }}>
                  <span style={{ paddingRight: 12 }}>Due Date: </span>
                  {requestDetails.dueDate}
                </div>
              </Space>
              <StyledDiv>
                <StyledItem>
                  <h3 style={{ marginBottom: 0, marginTop: 24 }}>State:</h3>
                  <h3
                    style={{
                      color: getStatusColor(requestDetails.status),
                      marginBottom: 0,
                      marginTop: 24,
                    }}
                  >
                    {requestDetails.status}
                  </h3>
                </StyledItem>

                <StyledItem>
                  <h3 style={{ marginBottom: 0, marginTop: 12 }}>
                    Estimate price:
                  </h3>
                  <h3 style={{ marginBottom: 0, marginTop: 12 }}>
                    {requestDetails.estimatePrice}
                  </h3>
                </StyledItem>
                <StyledItem>
                  <h3 style={{ marginBottom: 0, marginTop: 12 }}>
                    Actual price:
                  </h3>
                  <h3 style={{ marginBottom: 0, marginTop: 12 }}>
                    {requestDetails.actualPrice}
                  </h3>
                </StyledItem>
              </StyledDiv>
            </div>
            <div
              style={{
                display: "flex",
                width: "100%",
                marginTop: 24,
                height: height - 170 - 66,
                borderWidth: 0,
                borderTopWidth: 2,
                borderStyle: "solid",
                borderColor: colorBorder,
              }}
            >
              <div style={{ width: "20%" }}>
                <Button
                  // onClick={showAddModal}
                  style={{
                    width: "100%",
                    height: 120,
                    fontSize: 20,
                    borderWidth: 0,
                    borderRadius: 0,
                    borderTopWidth: 0,
                    borderRightWidth: 0,
                    borderBottomWidth: 1,
                    borderLeftWidth: 0,
                    boxShadow: "none",
                  }}
                >
                  <div
                    style={{
                      float: "left",
                      height: "100%",
                      alignContent: "center",
                      padding: "5%",
                      width: "80%",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Download All
                  </div>
                  <div
                    style={{
                      position: "absolute",
                      right: "5%",
                      height: "100%",
                      alignContent: "center",
                      padding: "5%",
                    }}
                  >
                    <DownloadOutlined />
                  </div>
                </Button>

                <List>
                  <div
                    style={{
                      borderWidth: 0,
                      borderStyle: "solid",
                      borderColor: colorBorder,
                    }}
                  >
                    <VirtualList
                      data={resultFileList}
                      height={height - 360}
                      itemKey="title"
                      style={{
                        borderWidth: "0px",
                        borderTopWidth: 0,
                      }}
                    >
                      {(item, index) => {
                        return (
                          <List.Item
                            key={item.uid}
                            style={{
                              padding: 0,
                              height: 40,
                              overflowX: "hidden",
                              borderWidth: 0,
                              marginTop: index === 0 ? 0 : 8,
                            }}
                          >
                            <Button
                              onClick={() => {
                                console.log(item.name);
                                window.location.href = item.url;
                              }}
                              type="text"
                              style={{
                                width: "100%",
                                height: "100%",
                                paddingLeft: 0,
                                paddingRight: 0,
                                paddingTop: 0,
                                paddingBottom: 0,
                                borderRadius: 0,
                                textAlign: "left",
                                borderWidth: 0,
                                borderTopWidth: index === 0 ? 0 : 1,
                                borderColor: colorBorder,
                              }}
                            >
                              <div
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  fontSize: 14,
                                  alignContent: "center",
                                  paddingLeft: "10%",
                                  paddingRight: 36,
                                  paddingTop: 8,
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                {item.name}
                                <DownloadOutlined
                                  style={{
                                    position: "absolute",
                                    right: 15,
                                    fontSize: 20,
                                  }}
                                />
                              </div>
                            </Button>
                          </List.Item>
                        );
                      }}
                    </VirtualList>
                  </div>
                </List>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  width: "80%",
                  borderWidth: 2,
                  borderLeftColor: colorBorder,
                  borderLeftStyle: "solid",
                }}
              >
                <Spin spinning={CommentsLoading}>
                  <List
                    style={{
                      height: "100%",
                    }}
                  >
                    <div>
                      <div
                        style={{
                          height: 120,
                          borderBottomWidth: 1,
                          borderBottomColor: colorBorder,
                          borderBottomStyle: "solid",
                        }}
                      >
                        <div
                          style={{
                            height: 80,
                            boxShadow: "none", // Remove the shadow
                            backgroundColor: "transparent",
                            fontWeight: "bold",
                            fontSize: 20,
                            alignContent: "center",
                            textAlign: "center",
                          }}
                        >
                          Comments
                        </div>
                        <StyledRadioGroup
                          value={commentSelection}
                          onChange={onCommentMenuClick}
                          style={{
                            width: "100%",

                            zIndex: 2,
                            position: "relative",
                            marginBottom: -8,
                            fontWeight: "bold",
                            fontSize: 14,
                            borderWidth: 0,
                            justifyContent: "space-evenly",
                          }}
                        >
                          <Radio.Button
                            value="active"
                            style={{
                              width: "50%",
                              height: "100%",
                              alignContent: "center",
                              borderLeftWidth: 0,
                              borderTopWidth: 0,
                              borderBottomWidth: 0,
                              borderRadius: 0,
                              borderRightWidth: 0,
                              textAlign: "center",
                              backgroundColor: "transparent",
                            }}
                          >
                            Active
                          </Radio.Button>
                          <Radio.Button
                            value="resolved"
                            style={{
                              width: "50%",
                              height: "100%",
                              alignContent: "center",
                              borderBottomWidth: 0,
                              borderRightWidth: 0,
                              borderTopWidth: 0,
                              borderRadius: 0,
                              borderLeftWidth: 0,
                              borderLeftColor: "transparent",
                              borderLeftStyle: "none",
                              textAlign: "center",
                              backgroundColor: "transparent",
                            }}
                          >
                            Resolved
                          </Radio.Button>
                        </StyledRadioGroup>
                      </div>
                      <VirtualList
                        data={
                          commentSelection === "active"
                            ? commentList
                            : resolvedCommentList
                        }
                        height={height - 474}
                        itemKey="uid"
                        style={{
                          borderTopWidth: 0,
                          borderBottomWidth: 1,
                          borderColor: colorBorder,
                          borderStyle: "solid",
                          borderLeftWidth: 0,
                          borderRightWidth: 0,

                          zIndex: 1,
                        }}
                      >
                        {(item, index) => {
                          let borderTop = false;
                          if (index === 0) {
                            borderTop = true;
                          }
                          let imageDisplay = "none";
                          if (item.picture !== "") {
                            imageDisplay = "";
                          }
                          return (
                            <List.Item
                              key={item.uid}
                              style={{
                                fontSize: 16,
                                padding: 16,
                                borderTopWidth: borderTop ? 0 : 1,
                                borderStyle: "solid",
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                borderRightWidth: 0,
                                marginTop: 8,
                                borderColor: colorBorder,
                                alignContent: "center",
                              }}
                            >
                              <Space direction="vertical">
                                <div>{item.content}</div>
                                <Image
                                  width={150}
                                  src={item.picture}
                                  style={{ display: imageDisplay }}
                                ></Image>
                                <div style={{ fontSize: 12 }}>
                                  Published Date: {item.publishDate}
                                </div>
                              </Space>
                            </List.Item>
                          );
                        }}
                      </VirtualList>
                      <div
                        style={{
                          height: 118,
                          alignContent: "center",
                          paddingLeft: 24,
                          borderLeftRadius: 10,
                          borderRightRadius: 10,
                          backgroundColor: colorBgLayout,
                        }}
                      >
                        <Flex direction="horizontal" style={{ width: "100%" }}>
                          <Input.TextArea
                            placeholder="Type your message"
                            autoSize={{ minRows: 3, maxRows: 3 }}
                            style={{ width: "95%" }}
                            onChange={(e) => setInputMessage(e.target.value)}
                            value={inputMessage}
                          />
                          <Space
                            direction="vertical"
                            size={"large"}
                            style={{
                              paddingLeft: 16,
                              paddingRight: 16,
                              maxWidth: "20vw",
                            }}
                          >
                            <Upload {...attachmentProps} fileList={uploadFile}>
                              <LinkOutlined
                                style={{
                                  fontSize: 16,
                                  paddingTop: 16,
                                }}
                              />
                            </Upload>
                            <SendOutlined
                              style={{ fontSize: 16, paddingBottom: 8 }}
                              onClick={(e) => {
                                handleAddComment();
                              }}
                            />
                          </Space>
                        </Flex>
                      </div>
                    </div>
                  </List>
                </Spin>
              </div>
            </div>
          </Layout.Content>
        )}
      </RequestSideMenu>
    </MainHeader>
  );
}

export default withThemeSwitch(RequestDetails);

const StyledDiv = styled.div`
  height: 150px;
  width: 250px;
  float: right;
  margin-bottom: 18px;
  font-size: 16px;
  padding: 10px;

  margin-right: 5vw;
  border-radius: 5%;

  transition: all 0.3s ease-in-out;
`;

const StyledRadioGroup = styled(Radio.Group)`
  height: 40 !important;

  .ant-radio-button-wrapper:not(:first-child)::before {
    content: none; // This line removes the separator
  }
`;

const StyledItem = styled.div`
  display: flex;
  justify-content: space-between;

  font-family: "Arial", sans-serif;
  margin-top: -5;
  span:last-child {
    font-weight: bold;
  }
`;

const resultFileList = [
  {
    uid: "-1",
    name: "Construction Guideline.png",
    url: "https://dynamic-media-cdn.tripadvisor.com/media/photo-o/10/dc/e6/a1/enjoy-beautiful-views.jpg?w=1200&h=-1&s=1",
  },
  {
    uid: "-2",
    name: "RF Sons Engieering.pdf",
    url: "https://rfsons.engineering/",
  },
  {
    uid: "-3",
    name: "Waterview tunnel.png",
    url: "https://www.pertronic.co.nz/assets/Uploads/Projects/201710-01.png",
  },
  {
    uid: "-4",
    name: "Construction Guideline.png",
    url: "https://dynamic-media-cdn.tripadvisor.com/media/photo-o/10/dc/e6/a1/enjoy-beautiful-views.jpg?w=1200&h=-1&s=1",
  },
  {
    uid: "-5",
    name: "RF Sons Engieering.pdf",
    url: "https://rfsons.engineering/",
  },
  {
    uid: "-6",
    name: "Waterview tunnel.png",
    url: "https://www.pertronic.co.nz/assets/Uploads/Projects/201710-01.png",
  },
  {
    uid: "-7",
    name: "Construction Guideline.png",
    url: "https://dynamic-media-cdn.tripadvisor.com/media/photo-o/10/dc/e6/a1/enjoy-beautiful-views.jpg?w=1200&h=-1&s=1",
  },
  {
    uid: "-8",
    name: "RF Sons Engieering.pdf",
    url: "https://rfsons.engineering/",
  },
  {
    uid: "-9",
    name: "Waterview tunnel.png",
    url: "https://www.pertronic.co.nz/assets/Uploads/Projects/201710-01.png",
  },
  {
    uid: "-10",
    name: "Construction Guideline.png",
    url: "https://dynamic-media-cdn.tripadvisor.com/media/photo-o/10/dc/e6/a1/enjoy-beautiful-views.jpg?w=1200&h=-1&s=1",
  },
  {
    uid: "-11",
    name: "RF Sons Engieering.pdf",
    url: "https://rfsons.engineering/",
  },
  {
    uid: "-12",
    name: "Waterview tunnel.png",
    url: "https://www.pertronic.co.nz/assets/Uploads/Projects/201710-01.png",
  },
  {
    uid: "-13",
    name: "Waterview tunnel.png",
    url: "https://www.pertronic.co.nz/assets/Uploads/Projects/201710-01.png",
  },
];
// const commentList = [
//   {
//     uid: 1,
//     content: "In RF Sons Engineering page 2, there is a mistake about xxxx.",
//     picture:
//       "https://dynamic-media-cdn.tripadvisor.com/media/photo-o/10/dc/e6/a1/enjoy-beautiful-views.jpg?w=1200&h=-1&s=1",
//     publishDate: "2021-09-01",
//     state: "active",
//     resolvedDate: "-",
//   },
//   {
//     uid: 2,
//     content: "In xxx project, the site top view should be in this form.",
//     picture:
//       "https://upload.wikimedia.org/wikipedia/commons/9/9b/Lochaber_Centre_Site_Plan.jpg",
//     publishDate: "2021-09-02",
//     state: "active",
//     resolvedDate: "-",
//   },
//   {
//     uid: 3,
//     content: "Rebuild xxx model, seems the file has been damaged.",
//     picture: "",
//     publishDate: "2021-09-03",
//     state: "active",
//     resolvedDate: "-",
//   },
//   {
//     uid: 4,
//     content: "In RF Sons Engineering page 2, there is a mistake about xxxx.",
//     picture:
//       "https://dynamic-media-cdn.tripadvisor.com/media/photo-o/10/dc/e6/a1/enjoy-beautiful-views.jpg?w=1200&h=-1&s=1",
//     publishDate: "2021-09-04",
//     state: "active",
//     resolvedDate: "-",
//   },
// ];
