import { Menu, Layout, theme, Modal } from "antd";
import React, { useState, useContext, useEffect } from "react";
import {
  BarsOutlined,
  PlusCircleOutlined,
  CommentOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useGlobalContext } from "../GlobalContext";

const requestList = {
  name: "Request List",
  icon: BarsOutlined,
  link: "request_list",
  disabled: false,
};
const createRequest = {
  name: "Create A Request",
  icon: PlusCircleOutlined,
  link: "request_creation",
  disabled: false,
};
const message = {
  name: "Message",
  icon: CommentOutlined,
  link: "request_message",
  disabled: true,
};

export default function RequestSideMenu(props) {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const navigate = useNavigate();
  const [selectedKey, setSelectedKey] = useState(props.select);
  const { RequestDetailLoading, RequestListLoading } =
    useContext(useGlobalContext);
  const [navigationPath, setNavigationPath] = useState(null);

  useEffect(() => {
    if (!RequestDetailLoading && !RequestListLoading && navigationPath) {
      navigate(navigationPath);
    }
  }, [RequestDetailLoading, RequestListLoading, navigationPath, navigate]);

  const navigators = [requestList, createRequest, message].map(
    (item, index) => {
      const key = String(index + 1);
      const link = "/" + item.link;

      return {
        key: `${key}`,
        icon: React.createElement(item.icon),
        disabled: item.disabled,
        label: (
          <div
            onClick={(e) => {
              if (props.warning === true) {
                Modal.confirm({
                  title: "Leave this page?",
                  content:
                    "Do you want to save the progress you made for this request?",
                  onOk: () => {
                    props.modifyTempRequest();
                    setSelectedKey(key);
                    setNavigationPath(link);
                  },
                  onCancel: () => {
                    props.deleteTempRequest();
                    setSelectedKey(key);
                    setNavigationPath(link);
                  },
                  onOkText: "Yes",
                  cancelText: "Don't save",
                });
              } else if (props.deleteCommentFile) {
                props.deleteCommentFile();
                navigate(link);
                setSelectedKey(key);
              } else {
                navigate(link);
                setSelectedKey(key);
              }
            }}
          >
            {item.name}
          </div>
        ),
      };
    }
  );
  return (
    <Layout>
      <Layout.Sider
        width={220}
        style={{
          background: colorBgContainer,
          paddingTop: 24,
        }}
      >
        <Menu
          theme="light"
          items={navigators}
          mode="inline"
          defaultSelectedKeys={[props.select]}
          selectedKeys={[selectedKey]}
          style={{
            height: "100%",
            borderRight: 0,
            borderRadius: borderRadiusLG,
          }}
        />
      </Layout.Sider>
      {props.children}
    </Layout>
  );
}
